
/**
 * Created by wangtong on 2022/12/7
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {Browser} from "@sandan/febasic";
import {CommonApi} from "@/api/common";
import {buildOssUrl} from "@/utils";

@Component({})
export default class BeijingJianKangBao extends Vue {
    imageUrl = ""

    onSelectFile() {
        Browser.selectFile("image/png,image/jpg,image/jpeg,image/gif", async (file: File) => {
            console.log(`file filename: ${file.name}`)
            let path = (await CommonApi.uploadImage("beijing-jiankangbao", file)).items[0]?.path || ""
            this.imageUrl = buildOssUrl(path)
        })
    }

}
